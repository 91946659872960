<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        How many moles of nitrogen atoms are present in a
        <number-value :value="mass" unit="\text{g}" /> sample of pyrazine,
        <stemble-latex content="$\ce{C4H4N2}?$" />
      </p>

      <calculation-input
        v-model="inputs.studentAnswer"
        prepend-text="Amount:"
        append-text="mol"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemSIU131_Task12',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
  },
};
</script>
